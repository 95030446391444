import React, {useEffect} from 'react';
import './App.scss';
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Admin from "./pages/Admin/Admin";
import Episodes from './pages/Episodes/Episodes';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import ReactGA from 'react-ga';

function App() {
    const TRACKING_ID = "G-6TBCGS2JR8";
    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/episodes' element={<Episodes />} />
                    <Route path='/admin' element={<Admin />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
