import './Home.scss'
import pbsLogo from '../../assets/images/PBS Logo Vertical.svg';
import corpsLogo from '../../assets/images/Corps Logo.svg';
import corpsStaffPhoto from '../../assets/images/Corps Staff Photo 2023.jpg';
import robotImage from '../../assets/images/Robot_pose 1.svg';
import header from '../../assets/images/Header.svg';
import leftArrow from '../../assets/images/leftArrow.svg'
import rightArrow from '../../assets/images/rightArrow.svg'
import {Link} from "react-router-dom";
import API, {Credit} from "../../api/api";
import React, {useEffect, useState} from "react";
import {format} from "date-fns";

function Home() {
    const [people, setPeople] = useState([]);
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        API.getPeople().then((response) => {
            // @ts-ignore
            setPeople(response);
        })
    }, [])

    const [startSlice, setStartSlice] = useState(0);
    const [maxSlice, setMaxSlice] = useState(40);
    const [maxSliceMobile, setMaxSliceMobile] = useState(20);
    const [touchStart, setTouchStart] = useState(0);

    const moveRight = () => {
        if (startSlice == 0) {
            setStartSlice(people.length - ((people.length % 20) ? people.length % 20 : 20))
            setMaxSliceMobile(people.length)
        }
        if (startSlice == people.length - ((people.length % 20) ? people.length % 20 : 20)) {
            setMaxSliceMobile(startSlice)
            setStartSlice(prev => prev - 20)
        } else if (startSlice > 0) {
            setStartSlice(prev => prev - 20);
            {
                setMaxSliceMobile(prev => prev - 20)
            }
        }
    }

    const moveLeft = () => {
        if (maxSliceMobile < people.length) {
            setStartSlice(maxSliceMobile);
            {
                setMaxSliceMobile(maxSliceMobile + 20)
            }
        } else {
            setStartSlice(0)
            setMaxSliceMobile(20)
        }
    }

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStart(event.changedTouches[0].screenX);
    };

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        handleGesture(event.changedTouches[0].screenX);
    };


    const handleGesture = (touchendX: number) => {
        if ((touchStart - touchendX) > 20) {
            moveLeft();
        }

        if ((touchendX - touchStart) > 20) {
            moveRight();
        }
    }

    const creditsLayout = (credit: Credit[]) => {
        return credit.slice(startSlice, maxSlice).map((credits) => {
                return (
                    <div className='credits-text' key={credits.id}>
                        <p className='name' dangerouslySetInnerHTML={
                            {__html: credits.name}
                        }/>
                        <p className='team'>{credits.team}</p>
                    </div>
                )
            }
        )
    }
    const creditsLayoutMobile = (credit: Credit[]) => {
        return credit.slice(startSlice, maxSliceMobile).map((credits) => {
                return (
                    <div className='credits-text' key={credits.id}>
                        <p className='name' dangerouslySetInnerHTML={
                            {__html: credits.name}
                        }/>
                        <p className='team'>{credits.team}</p>
                    </div>
                )
            }
        )
    }

    return (
        <div className='home'>
            <div className='content'>
                <header>
                    <img src={header} alt=''/>
                </header>
                <div className='padding'>
                    <div className='about-show' id="about-show">
                        <h2>Hey, What's "The Loading Zone"?</h2>
                        <div className='about-show-content'>
                            <div className='about-show-text'>
                                <p>
                                    In 2017, Ball State PBS approached the Digital Corps about creating a series of
                                    kid-friendly, educational shorts for the local TV station.
                                </p>
                                <p>
                                    Staff and students brainstormed
                                    character ideas, video formats, and topic suggestions before pitching the robot
                                    universe
                                    that would ultimately become “The Loading Zone.”
                                    Now, Digital Corps students write, star in, film, animate, and edit episodes
                                    explaining
                                    hundreds of STEM topics to children around the country.
                                </p>
                            </div>
                            <div className='iframe'>
                                <iframe src="https://www.youtube.com/embed/videoseries?list=PLb294dtN1KEGPUFERZdhMmXZHvhT-y75i" frameBorder="0"
                                        allow="fullscreen"
                                        allowFullScreen>
                                </iframe>
                                <p>
                                    Press play to start watching The Loading Zone, or select the menu icon to choose from the full list of episodes!
                                </p>
                            </div>
                            <div className='iframe-mobile'>
                                <iframe src="https://www.youtube.com/embed/videoseries?list=PLb294dtN1KEGPUFERZdhMmXZHvhT-y75i" frameBorder="0"
                                        allow="fullscreen"
                                        allowFullScreen>
                                </iframe>
                                <p>
                                    Press play to start watching The Loading Zone, or select the menu icon to choose from the full list of episodes!
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='about-billy-and-penelope'>
                        <div className='billy-and-penelope'>
                            <div className='image'>
                                <img src={robotImage} alt=''/>
                            </div>
                            <h2>Meet Billy and Penelope</h2>
                            <div className='billy-penelope-about-content'>
                                <p>Learning is better together! Billy and Penelope are best friends, going to school and
                                    exploring exciting questions side by side. Curious, exuberant, and inseparable, the
                                    robotic duo takes on the world with childlike wonder. They’re always ready to call
                                    up a
                                    friend from the Ball State Digital Corps and learn more about STEM!
                                </p>
                            </div>
                        </div>

                    </div>
                    <hr className='hr'/>
                    <div className='episodes-header'>
                        <div className='episode-title'>
                            <h2>Download Episodes</h2>
                        </div>
                        <p className='bold'>
                            PBS Member Stations are encouraged to download and air TLZ episodes between
                            children’s programming.
                        </p>
                        <p>
                            Each episode of “The Loading Zone” is a 60-second exploration of a STEM topic featuring
                            the animated main characters, Billy and Penelope, and a live action Digital Corps
                            student. Episodes follow a three-scene format in which Billy and Penelope ask a
                            question, the Digital Corps student answers the question, and Billy and Penelope prepare
                            to use their new knowledge.
                        </p>
                        <p className='italics'>Episodes are provided at 1080p and are ready for broadcast.</p>

                        {sessionStorage.getItem('session_key') ? <Link to={'/episodes'}>Download Episodes</Link> :
                            <Link to={'/login'}>Sign-in to Download</Link>}
                    </div>
                    <div className='desktop-view'>
                        <div className='billy-and-penelope'>
                            <div className='image'>
                                <img src={robotImage} alt=''/>
                            </div>
                            <h2>Meet Billy and Penelope</h2>
                            <div className='billy-penelope-about-content'>
                                <p>
                                    Learning is better together! Billy and Penelope are best friends, going to school
                                    and
                                    exploring exciting questions side by side. Curious, exuberant, and inseparable, the
                                    robotic duo takes on the world with childlike wonder. They’re always ready to call
                                    up a
                                    friend from the Ball State Digital Corps and learn more about STEM!
                                </p>
                            </div>
                        </div>
                        <div className='episodes-header-desktop'>
                            <div className='episode-title'>
                                <h2>Download Episodes</h2>
                            </div>
                            <p className='bold'>
                                PBS Member Stations are encouraged to download and air TLZ episodes between
                                children’s programming.
                            </p>
                            <p>
                                Each episode of “The Loading Zone” is a 60-second exploration of a STEM topic featuring
                                the animated main characters, Billy and Penelope, and a live action Digital Corps
                                student. Episodes follow a three-scene format in which Billy and Penelope ask a
                                question, the Digital Corps student answers the question, and Billy and Penelope prepare
                                to use their new knowledge.
                            </p>
                            <p className='italics'>Episodes are provided at 1080p and are ready for broadcast.</p>
                            {sessionStorage.getItem('session_key') ? <Link to={'/episodes'}>Download Episodes</Link> :
                                <Link to={'/login'}>Sign-in to Download</Link>}
                        </div>
                    </div>
                    <hr id='about-corps'/>
                    <div className='about-corps'>
                        <h2>Your Friends from the Digital Corps</h2>
                        <div className='about-corps-content'>
                            <div className='about-corps-photo'>
                                <img src={corpsStaffPhoto} alt='The Digital Corps Staff'/>
                            </div>
                            <div className='about-corps-text'>
                                <p>The Ball State Digital Corps is an agency of problem solvers, big thinkers, and
                                    creatives.
                                    We are Ball State University students from multiple disciplines who work together to
                                    create innovative, digital solutions for our campus and community partners.
                                </p>
                            </div>
                            <a href='https://digitalcorps.bsu.edu/' target="_blank">Visit the Corps</a>
                            <div className='credits'>
                                <h2>Special Thanks To:</h2>
                                <div className='credits-content'>
                                    <div className='credits-arrow'>
                                        <img src={leftArrow} alt='<'
                                             onClick={
                                                 event => {
                                                     if (startSlice == 0) {
                                                         setStartSlice(people.length - ((people.length % 40) ? people.length % 40 : 40))
                                                         setMaxSlice(people.length)
                                                     }
                                                     if (startSlice == people.length - ((people.length % 40) ? people.length % 40 : 40)) {
                                                         setMaxSlice(startSlice)
                                                         setStartSlice(prev => prev - 40)
                                                     } else if (startSlice > 0) {
                                                         setStartSlice(prev => prev - 40);
                                                         {
                                                             setMaxSlice(prev => prev - 40)
                                                         }
                                                     }
                                                 }}/>
                                    </div>
                                    <div className='credit-names'>
                                        {creditsLayout(people)}
                                    </div>
                                    <div className='credits-arrow'>
                                        <img src={rightArrow} alt='>' onClick={
                                            event => {
                                                if (maxSlice < people.length) {
                                                    setStartSlice(maxSlice);
                                                    {
                                                        setMaxSlice(maxSlice + 40)
                                                    }
                                                } else {
                                                    setStartSlice(0)
                                                    setMaxSlice(40)
                                                }
                                            }}/>
                                    </div>
                                </div>
                                <div className='credits-content-mobile' onTouchEnd={handleTouchEnd}
                                     onTouchStart={handleTouchStart}>
                                    <div className='credits-arrow'>
                                        <img src={leftArrow} alt='<'
                                             onClick={moveRight}/>
                                    </div>
                                    <div className='credit-names'>
                                        {creditsLayoutMobile(people)}
                                    </div>
                                    <div className='credits-arrow'>
                                        <img src={rightArrow} onClick={moveLeft} alt='>'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='logos'>
                    <a href='https://ballstatepbs.org/' target='_blank'>
                        <img src={pbsLogo} alt='Ball State PBS'/>
                    </a>
                    <a href='https://digitalcorps.bsu.edu/' target="_blank">
                        <img src={corpsLogo} alt='The Digital Corps'/>
                    </a>
                </div>
                <div className='copyright'>
                    <p>Copyright © {format(new Date(), "y")} Ball State University</p>
                </div>
            </div>
        </div>
    )
}

export default Home;