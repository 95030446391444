import React, {useEffect, useState} from "react";
import './Episodes.scss'
import API, {Category, Episode} from "../../api/api";
import {format} from "date-fns"
import {Link} from "react-router-dom";
import download from "../../assets/images/Episode Download Icon.svg";
import exit from "../../assets/images/Exit Icon.svg";
import seeMore from "../../assets/images/see more.svg";
// @ts-ignore
import BillyPeakingChrome from '../../assets/videos/billy_peek-vp9-chrome.webm';
// @ts-ignore
import BillyPeakingSafari from '../../assets/videos/billy_peek-hevc-safari.mp4';
// @ts-ignore
import PenelopePeakingChrome from '../../assets/videos/penelope_peek-vp9-chrome.webm';
// @ts-ignore
import PenelopePeakingSafari from '../../assets/videos/penelope_peek-hevc-safari.mp4';
import header from "../../assets/images/Header.svg";
import pbsLogo from "../../assets/images/PBS Logo Vertical.svg";
import corpsLogo from "../../assets/images/Corps Logo.svg";
import leftDoor from "../../assets/images/door left.svg";
import rightDoor from "../../assets/images/door right.svg";
import leftDoorMonitor from '../../assets/images/door left monitors.svg'
import rightDoorMonitor from '../../assets/images/door right monitors.svg'
import tv from '../../assets/images/tv.png'

function Episodes() {
    const [layout, setLayout] = useState('grid');
    const [selectedEpisode, setSelectedEpisode] = useState<null | Episode>(null);
    const [searchText, setSearchText] = useState("")
    const [episodes, setEpisodes] = useState([] as Episode[])
    const [selectedCategory, setSelectedCategory] = useState("");
    const [downloadedEpisodes, setDownloadedEpisodes] = useState([] as number[])
    const [downloading, setDownloading] = useState(false);
    const [activeCategories, setActiveCategories] = useState([] as Category[])
    const [doorAnimationPlayed, setDoorAnimationPlayed] = useState(false);
    const [maxSlice, setMaxSlice] = useState(6);
    const startSlice = 0;

    document.addEventListener('keydown', function (event) {
        const key = event.key; // const {key} = event; in ES6+
        if (key === "Escape") {
            setSelectedEpisode(null);
        }
    });

    useEffect(() => {
        API.getEpisodes().then((response) => {
            if (response) {
                // @ts-ignore
                setEpisodes(response)
            }
        })
        API.getUserDownloads(String(sessionStorage.getItem("user_id"))).then((response) => {
            if (response) {
                // @ts-ignore
                response.map((download) => {
                    setDownloadedEpisodes(downloadedEpisodes.concat(download.episode_id))
                })
            }
        })
        API.getActiveCategories().then((response) => {
            if (response) {
                // @ts-ignore
                setActiveCategories(response)
            }

        })
        const doorAnimationStatus = sessionStorage.getItem('doorAnimationPlayed');
        if (!doorAnimationStatus) {
            setDoorAnimationPlayed(true);
            sessionStorage.setItem('doorAnimationPlayed', 'true');
        }
    }, [])

    const downloadEpisode = (episodeID: number) => {
        const formData = new FormData();
        formData.append("user_id", sessionStorage.getItem("user_id")!)
        formData.append("folderPath", "National")
        return fetch(`https://tlz-backend.apsoprojects.org/episodes/download/${episodeID}`, {
            method: "POST",
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.text()
            }
            throw new Error("Unable to download episode!");
        }).then(async response => {

            setDownloadedEpisodes(downloadedEpisodes.concat(episodeID))
            let a = document.createElement('a');
            a.href = response;
            await a.click();
            return response;
        })
    }

    const filterEpisode = episodes.filter(
        (episode) =>
            (episode?.title
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText) || (episode.desc && episode.desc
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText)
            || (episode.episode_number.toString()
                .includes(searchText.toLowerCase()) || !searchText)
    )
    const onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCategory(event.target.value);
    }

    const downloadAll = () => {
        const formData = new FormData();
        formData.append("user_id", sessionStorage.getItem("user_id")!)
        return fetch(`https://tlz-backend.apsoprojects.org/episodes/downloadAll`, {
            method: "POST",
            body: formData
        }).then(response => {
            if (response) {
                return response.text()
            }
            throw new Error("Unable to download episode!");
        })
    }

    const viewMore = () => {
        setMaxSlice(maxSlice + 6)
    }

    const filteredEpisodes = episodes.filter((episode) => {
        return (episode.category === selectedCategory || (selectedCategory === "")) && ((episode?.title
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText) || (episode.desc && episode.desc
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText)
            || (episode.episode_number.toString()
                .includes(searchText.toLowerCase()) || !searchText)
            || (episode.category.toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText)
        )
    })

    const episodeLayout = () => {
        if (filteredEpisodes.length != 0) {
            const slicedEpisodes = filteredEpisodes.slice(startSlice, maxSlice);
            return slicedEpisodes.map((episode) => {
                    const getYouTubeVideoId = (url: string) => {
                        // Getting the part of an embedded YouTube url that is after the embed but before the ?
                        const regex = /embed\/([^?]+)/;

                        // Getting the YouTube ID with /embed and by itself
                        const match = url.match(regex);

                        // Returning only the YouTube ID
                        return match && match[1] ? match[1] : null;
                    }

                    const getThumbnailUrl = (episode: Episode) => {
                        if (episode.video_url) {
                            const videoId = getYouTubeVideoId(episode.video_url);
                            return videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : undefined;
                        }
                        return undefined;
                    };

                    const thumbnailUrl = getThumbnailUrl(episode);

                    // @ts-ignore
                    return (
                        <div className='episode-content'>
                            <div className='episode-thumbnail' onClick={e => (setSelectedEpisode(episode))}>
                                <img id='episode-thumbnail-image' alt='Thumbnail'
                                     src={thumbnailUrl}/>
                            </div>
                            <div className='episode-text-and-download'>
                                <div className='episode-text'>
                                    <div className='download-layout'>
                                        <div className='new-episode'>
                                            <div className='episode-content-text'>
                                                <p className='episode-number'>Episode {episode.episode_number}</p>
                                                <p className='episode-title'>{episode.title}</p>
                                                <p className='episode-category'>Subject: {episode.category}</p>
                                                <p className='episode-date'>Date
                                                    Added: {format(new Date(new Date(episode.created_at).valueOf() + new Date(episode.created_at).getTimezoneOffset() * 60 * 1000), "LLLL d, y")}</p>
                                            </div>
                                            <div className='grid-new-and-downloaded'>

                                                {(sessionStorage.getItem('last_login') ? sessionStorage.getItem('last_login') : null)! <= (episode.created_at) &&
                                                    <p className='new'>NEW!</p>
                                                }

                                                {downloadedEpisodes.includes(episode.id) &&
                                                    <p className='downloaded'>DOWNLOADED!</p>}
                                            </div>
                                        </div>
                                        <div className='list-view-download-and-new'>
                                            <button className='download-button' value="download" onClick={(e) => {
                                                downloadEpisode(episode.id)
                                            }}><img src={download} alt=''/>Download
                                            </button>
                                            {(sessionStorage.getItem('last_login') ? sessionStorage.getItem('last_login') : null)! <= (episode.created_at) &&
                                                <p className='new'>NEW!</p>
                                            }
                                            {downloadedEpisodes.includes(episode.id) &&
                                                <p className='downloaded'>DOWNLOADED!</p>}
                                        </div>
                                    </div>
                                    <p className='episode-content-text description'>{episode.desc}</p>
                                </div>
                                <div className='episode-download'>
                                    <button className='download-button' value="download" onClick={(e) => {
                                        downloadEpisode(episode.id)
                                    }}><img src={download} alt=''/>Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            )
        } else {
            return (
                <div className={"no-episodes-div"}>
                    <p className={"no-episodes-message"}>No Available Episodes</p>
                </div>
            )
        }
    }

    const styles = {
        maxWidth: downloading ? '81%' : '1400px',
        width: downloading && window.innerWidth >= 2000 ? '55%' : 'auto',
    };
    {
        return (
            <div className='episodes-page'>
                <div className={`doors-open ${doorAnimationPlayed ? 'play-animation' : ''}`}>
                {/*<div className={'doors-open play-animation'}>*/}

                <div className='left-door'>
                        <img src={leftDoor} alt="Left Door"/>
                    </div>
                    <div className='right-door'>
                        <img src={rightDoor} alt="Right Door"/>
                    </div>
                    <div className='left-door-monitor'>
                        <img src={leftDoorMonitor} alt="Left Door"/>
                    </div>
                    <div className='right-door-monitor'>
                        <img src={rightDoorMonitor} alt="Right Door"/>
                    </div>
                </div>
                <div className='content' style={styles}>
                    <header>
                        <img src={header} alt=''/>
                    </header>
                    <div className='padding'>
                        <div className='episodes'>
                            <div className='episodes-header'>
                                <div className='episode-title'>
                                    <div className='title-header'>
                                        <h2>Episodes</h2>
                                        {episodes[0] && <p className='updated-on'>Last
                                            Updated: {format(new Date(new Date(episodes[0].created_at).valueOf() + new Date(episodes[0].created_at).getTimezoneOffset() * 60 * 1000), "LLLL d, y")}</p>}
                                    </div>
                                    <p>
                                        Each episode of “The Loading Zone” is a 60-second exploration of a STEM
                                        topic
                                        featuring
                                        the animated main characters, Billy and Penelope, and a live action Digital
                                        Corps
                                        student. Episodes follow a three-scene format in which Billy and Penelope
                                        ask a
                                        question, the Digital Corps student answers the question, and Billy and
                                        Penelope
                                        prepare
                                        to use their new knowledge.
                                    </p>
                                </div>
                                <div className='download-episodes'>
                                    <div className='tv'>
                                        <img src={tv} alt='TV Image'/>
                                    </div>
                                    <div className='download-button' onClick={(e) => {
                                        setDownloading(true);
                                        document.body.style.position = 'fixed';
                                        document.body.style.top = `-${window.scrollY}px`;
                                    }}><img src={download} alt=''/>Download All
                                    </div>
                                    {downloading &&
                                        <div className='dialog-box'>
                                            <div className='background-color'>
                                                <p className='confirm'>These are some pretty large files! Continuing
                                                    will direct
                                                    you
                                                    to a folder of zipped episodes. There, you can
                                                    download sets of 25 episodes at a time.</p>
                                                <div className='buttons dialog-buttons'>
                                                    <button className='delete' onClick={(e) => {
                                                        (setDownloading(false));
                                                        document.body.style.position = '';
                                                        document.body.style.top = '';
                                                    }}>No
                                                    </button>
                                                    <a className='save'
                                                       href="https://ballstate-my.sharepoint.com/:f:/g/personal/digitalcorps_bsu_edu/Ejfhy2cxeNNNj2xht9BGLs4BcCOUw0WIm6T3qfAl3nj-ww?e=hZrbGf"
                                                       target="_blank"
                                                       onClick={e => {
                                                           setDownloading(false);

                                                           document.body.style.position = '';
                                                           document.body.style.top = '';
                                                           downloadAll()
                                                       }}>Yes</a>
                                                </div>
                                            </div>
                                            <div className='background'/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr id="episodes"/>
                            <div className='episode-display'>
                                <div className='episode-display-header'>
                                    <select onChange={onCategoryChange}>
                                        <option value={""}>All</option>
                                        {activeCategories && activeCategories.map((category) =>
                                            <option value={category.category}>{category.category}</option>
                                        )}</select>
                                    <div className='view-buttons'>
                                        <button
                                            className={`grid-view view-button ${layout === 'grid' ? 'selected' : ''}`}
                                            onClick={e => {
                                                setLayout('grid')
                                            }}>Grid View
                                        </button>
                                        <button
                                            className={`list-view view-button ${layout === 'list' ? 'selected' : ''}`}
                                            onClick={e => {
                                                setLayout('list')
                                            }}>List View
                                        </button>
                                    </div>
                                    <div className='search-container'>
                                        <input className='search' id='search' placeholder='Search'
                                               onChange={(event) => setSearchText(event.target.value)}/>
                                        <div className="dropdown">
                                            <div className="container">
                                                {filterEpisode.length > 0 && (
                                                    filterEpisode.map(() => {
                                                        return (
                                                            <div className="item">

                                                            </div>
                                                        )
                                                    })
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='video-layout'>
                                    <div className={['TLZ-episodes', layout].join(' ')}>
                                        {episodeLayout()}
                                    </div>
                                    {selectedEpisode &&
                                        <div className='dialog-box'>
                                            <div className='background-color'>
                                                <div className='exit-button'>
                                                    <img src={exit} alt='Exit'
                                                         onClick={e => (setSelectedEpisode(null))}/>
                                                </div>
                                                <div className='episode-player'>
                                                    <div className='video-player'>
                                                        <iframe
                                                            src={selectedEpisode.video_url}
                                                            frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowFullScreen
                                                        />
                                                    </div>
                                                </div>
                                                <div className='episode-text'>
                                                    <p className='episode-number'>Episode {selectedEpisode.episode_number}</p>
                                                    <p className='episode-title'>{selectedEpisode.title}</p>
                                                    <p className='episode-category'>Subject: {selectedEpisode.category}</p>
                                                    <p className='episode-date'>Date
                                                        Added: {format(new Date(new Date(selectedEpisode.created_at).valueOf() + new Date(selectedEpisode.created_at).getTimezoneOffset() * 60 * 1000), "LLLL d, y")}</p>
                                                    <p className='episode-description'>{selectedEpisode.desc}</p>
                                                    <div>
                                                        <button className='download-button'
                                                                onClick={(e) => downloadEpisode(selectedEpisode.id)}>
                                                            <img
                                                                src={download} alt=''/>Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='background'/>
                                        </div>
                                    }
                                    {filteredEpisodes.length > maxSlice && filterEpisode.length > maxSlice &&
                                        <button className='view-more' onClick={viewMore}>
                                            <img src={seeMore} alt="View More"/>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr id="tutorial"/>
                        <div className='tutorial'>
                            <h2>Branding Tutorial</h2>
                            <div className='tutorial-content'>
                                <div className='tutorial-text'>
                                    <p>
                                        Watch this video to learn how to style “The Loading Zone” episodes for your PBS
                                        station.
                                    </p>
                                </div>
                                <div className='tutorial-video'>
                                    <div className='iframe'>
                                        <iframe src="https://player.vimeo.com/video/788649566?h=8efe35813f"
                                                width="640"
                                                height="564" frameBorder="0" allow="autoplay; fullscreen"
                                                allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tutorial-download'>
                        <video autoPlay loop muted playsInline className='billy'>
                            <source src={BillyPeakingSafari} type='video/mp4; codecs="hvc1"'/>
                            <source src={BillyPeakingChrome} type="video/webm"/>
                        </video>
                        <div className='download-button-div'>
                            <a className='download-button' target="_blank"
                               href='https://ballstate-my.sharepoint.com/:f:/g/personal/digitalcorps_bsu_edu/EoX_hLmEiY9BuiEmwFdt4YUBxGhALVLGsh9q1kQ0mSS4AA?e=cpCi4I'>
                                <img src={download} alt=''/>Download Assets
                            </a>
                            <p>
                                Download high-resolution assets, “The Loading Zone” logos, and press media-kit
                                for your station.
                            </p>
                        </div>
                        <video autoPlay loop muted playsInline className='billy'>
                            <source src={PenelopePeakingSafari} type='video/mp4; codecs="hvc1"'/>
                            <source src={PenelopePeakingChrome} type="video/webm"/>
                        </video>
                    </div>
                    <div className='home-button'>
                        <Link to="/">Back to Home</Link>
                    </div>
                    <div className='logos'>
                        <a href='https://ballstatepbs.org/' target='_blank'>
                            <img src={pbsLogo} alt='Ball State PBS'/>
                        </a>
                        <a href='https://digitalcorps.bsu.edu/' target="_blank">
                            <img src={corpsLogo} alt='The Digital Corps'/>
                        </a>
                    </div>
                    <div className='copyright'>
                        <p>Copyright © {format(new Date(), "y")} Ball State University</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Episodes;