export const API_URL = "https://tlz-backend.apsoprojects.org";

export type Person = {
    id: number;
    name: string;
    team: string
}

export type Station = {
    id: number;
    callsign: string;
    city: string;
    state: string;
}

export type APIError = {
    isError: boolean;
    code: number;
    codeText: string;
    message?: string;
};
export type Download = {
    episode_id: number;
}

export type Stats = {
    totalDownloads: {
        downloadCount: number;
    };
    totalStations: {
        stationCount: number;
    };
    activeStationLocations: MarkerType[];
    topEpisodes: [{
        episode_id: number;
        episode_number: string,
        title: string,
        episodeCount: number
    }];
    topCategories: [{
        category: string
        categoryCount: number
    }];
}

export type MarkerType = {
    station_id: number,
    latitude: number,
    longitude: number,
    callsign: string,
    city: string,
    state: string
}

export type Episode = {
    id: number;
    video_url: string;
    title: string;
    episode_number: string;
    desc?: string;
    category: string
    created_at: string;
}

export type Credit = {
    [key: string]: any;
    team: string;
    name: string;
}

export type Category = {
    category: string;
}
export type URLs = {
    url: string[];
}

const getKey = () => {
    return "NN}W\\2EV-rb0m-KSwvG{jkX~{+/~x$A^";
}

const getStations = async (): Promise<Station[] | APIError> => {
    const response = await fetch(`${API_URL}/stations`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results
}

const getStats = async (): Promise<Stats | APIError> => {
    const response = await fetch(`${API_URL}/stats`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results
}

const getPeople = async (): Promise<Person[] | APIError> => {
    const response = await fetch(`${API_URL}/credits`,  {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results
};

const getEpisodes = async (): Promise<Episode[]| APIError> => {
    const response = await fetch(`${API_URL}/episodes`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results
}
const getPaginatedEpisodes = async (page:string) => {
    return await fetch(`${API_URL}/paginatedEpisodes/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        return response.json();
    }).catch((response) => {
        return {
            errorCode: response.status,
            error: response.statusText,
        }
    });
}

const getUserDownloads = async (userID: string): Promise<Download[]| APIError> => {
    const response = await fetch(`${API_URL}/userDownloads/${userID}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results
}

const getActiveCategories = async (): Promise<Category[] | APIError> => {
    const response = await fetch(`${API_URL}/categories`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    const results = await response.json();
    //eslint-disable-next-line
    return results

}

const getURLs = async (): Promise<URLs| APIError> => {
    const response =await fetch(`${API_URL}/urls`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },

    });
    if (!response.ok) {
        return {
            isError: true,
            code: response.status,
            codeText: response.statusText,
            message: response.statusText,
        } as APIError;
    }
    return response.json();

}

const API = {
    getPeople,
    getStats,
    getStations,
    getEpisodes,
    getPaginatedEpisodes,
    getUserDownloads,
    getActiveCategories,
    getURLs
}

export default API;
