import './Login.scss'
import TLZLogo from '../../assets/images/TLZ Logo.svg'
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import 'react-select-search/style.css'
// @ts-ignore
import BillyIdleChrome from '../../assets/videos/Billy_Idle_Ani-vp9-chrome.webm';
// @ts-ignore
import BillyIdleSafari from '../../assets/videos/Billy_Idle_Ani-hevc-safari.mp4';
// @ts-ignore
import PenelopeIdleChrome from '../../assets/videos/Penelope_Idle_Ani-vp9-chrome.webm';
// @ts-ignore
import PenelopeIdleSafari from '../../assets/videos/Penelope_Idle_Ani-hevc-safari.mp4';
import API, {Station} from "../../api/api";
import SelectSearch from "react-select-search";
import {format} from "date-fns";

type User = {
    station_id: string
    email: string
}

function Login() {
    const navigate = useNavigate();
    const [station, setStation] = useState('0');
    const [stations, setStations] = useState([]);
    const [user, setUser] = useState({} as User)
    const stationList = stations && stations.map((station : Station) => {
        return {name: `${station.callsign} - ${station.city}`,
            value: station.id.toString()}
    })

    useEffect(() => {
        API.getStations().then((response) => {
            if(response){
                // @ts-ignore
                setStations(response)
            }
        })
    }, [])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const formData = new FormData()
        formData.append("email", user.email)
        formData.append("station_id", station)
        formData.append("last_login", format(new Date(), "y-M-d"))
        e.preventDefault();
        return fetch('https://tlz-backend.apsoprojects.org/users', {
            method: "POST",
            body: formData,
            credentials: 'same-origin'
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Invalid login");
            })
            .then(response => {
                // setIsAnimating(true);
                sessionStorage.setItem("user_id", response.User.id)
                sessionStorage.setItem("last_login", response.lastLogin ? response.lastLogin : '')
                navigate("/episodes");
            })
            .catch(err => {
                sessionStorage.clear();
                window.alert(err);
            })
    }

    // @ts-ignore
    return (
        <div className='login'>
            {/*<div className={['doors-closing', isAnimating ? 'animated' : ''].join(' ')}>*/}
            {/*    <img className='left-door' src={leftDoor}/>*/}
            {/*    <img className='right-door' src={rightDoor}/>*/}
            {/*</div>*/}
            <div className='container'>
                <div className='tlz-logo'>
                    <img src={TLZLogo} alt='The Loading Zone'/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='header-content'>
                        <h2>PBS Station Login</h2>
                        <div className='description'>
                            <p>
                                Select your PBS station from the dropdown menu and enter your station email.
                            </p>
                        </div>
                        <div className='user-interaction'>
                            <div className='select-station'>
                                <label>Station: </label>
                                <SelectSearch options={stationList}
                                              value={station}
                                            // @ts-ignore
                                              onChange={setStation}

                                              placeholder={"Select Station"} search autoComplete="on" />
                            </div>
                            <div className='enter-email'>
                                <label>Email: </label>
                                <input placeholder='iraglass@wedu.org' type={'email'} required={true} onChange={event => {
                                    setUser((user) => {
                                        return {...user, email: event.target.value} as User
                                    })
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className='continue-button'>
                        <button disabled={!( user.email && user.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && station)} >Let's Go!</button>
                    </div>
                </form>
            </div>
            <div className='billy-and-penelope'>
                <video autoPlay loop muted playsInline className='billy'>
                    <source src={BillyIdleSafari} type='video/mp4; codecs="hvc1"'/>
                    <source src={BillyIdleChrome} type="video/webm"/>
                </video>
                <video autoPlay loop muted playsInline className='penelope'>
                    <source src={PenelopeIdleSafari} type='video/mp4; codecs="hvc1"'/>
                    <source src={PenelopeIdleChrome} type="video/webm"/>
                </video>
            </div>
        </div>
    )
}

export default Login;