import './Admin.scss'
import tlzLogo from '../../assets/images/TLZ Logo.svg'
import React, {useEffect, useState} from "react";
import epNotFound from '../../assets/images/episode not found.svg';
import epFound from '../../assets/images/episode found.svg'
import API, {API_URL, Episode, Stats, MarkerType} from "../../api/api";
import { Map, Marker } from "pigeon-maps"
import {format} from "date-fns";
import {ResponsiveContainer, PieChart, Pie, Tooltip, Legend, Cell} from "recharts";

function Admin() {
    let i = 0
    const [stats, setStats] = useState<Stats>()
    const [run, setRun] = useState(0)
    const [categoryStat, setCategoryStat] = useState({
        "Arts": 0,
        "Engineering":0,
        "Health/Accessibility": 0,
        "Home/Life Skills": 0,
        "Math": 0,
        "Science" : 0,
        "Technology": 0,
        "Other": 0
    })
    const [episode, setEpisode] = useState({} as Episode)
    const [episodes, setEpisodes] = useState([] as Episode[])
    const [episodeNumber, setEpisodeNumber] = useState("")
    const [category, setCategory] = useState("")
    const [deletingEpisode, setDeletingEpisode] = useState<null | Episode>(null);
    const [downloadURLs, setDownloadURLs] = useState([] as string[])
    const [foundEp, setFoundEp] = useState(false)

    useEffect(() => {
        API.getEpisodes().then((response) => {
            if(response){
                // @ts-ignore
                setEpisodes(response)
            }
        })
        API.getStats().then((response) => {
            if(response){
                // @ts-ignore
                setStats(response)
                setRun(i++);
            }
            else{
                setRun(i++)
            }
        })
        setEpisode((episode) => {
            return {...episode, thumbnail_url: '.'} as Episode
        })
        API.getURLs().then((response) => {
            if(response){
                // @ts-ignore
                setDownloadURLs(response.url)
            }
        })
    },[])
    useEffect(() => {
        setEpisode((episode) => {
            return {...episode, thumbnail_url: 'TEST'} as Episode
        })
    }, [episodes])

    useEffect(() => {
        if(episodeNumber.length == 3){
            if(downloadURLs.find((element: string) =>
                element.includes(`EP_${episode.episode_number}`))){
                setFoundEp(true)
            }
        }
        if(episodeNumber.length<3){
            setFoundEp(false)
        }

    }, [episodeNumber])

    useEffect(() => {
        stats?.topCategories.map((category) => {
                switch(category.category){
                    case "Arts":
                        categoryStat.Arts = category.categoryCount
                        break;
                    case "Engineering":
                        categoryStat.Engineering = category.categoryCount
                        break;
                    case "Health/Accessibility":
                        categoryStat["Health/Accessibility"] = category.categoryCount
                        break;
                    case "Home/Life Skills":
                        categoryStat["Home/Life Skills"] = category.categoryCount
                        break;
                    case "Math":
                        categoryStat.Math = category.categoryCount
                        break;
                    case "Science":
                        categoryStat.Science = category.categoryCount
                        break;
                    case "Technology":
                        categoryStat.Technology = category.categoryCount
                        break;
                    case "Other":
                        categoryStat.Other = category.categoryCount
                        break;
                }
            })
    }, [stats, ])

    // @ts-ignore
    const pieChartData = [
        {
            'category' :"Arts",
            "value": categoryStat.Arts,
            'color': '#a6e76f'
        },
        {
            'category' :"Engineering",
            "value": categoryStat.Engineering,
            'color': '#0f7d63'
        },
        {
            'category' :"Health/Accessibility",
            'value': categoryStat["Health/Accessibility"],
            'color': '#72c5f1'
        },
        {
            'category': "Home/Life Skills",
            "value": categoryStat["Home/Life Skills"],
            "color": '#69caa4'
        },
        {
            'category' :"Math",
            "value": categoryStat.Math,
            'color': '#145b9b'
        },
        {
            'category': "Science",
            "value": categoryStat.Science,
            "color": '#49d3cc'
        },
        {
            'category' :"Technology",
            'value': categoryStat.Technology,
            'color': '#189ad3'
        },
        {
            'category' :"Other",
            "value": categoryStat.Other,
            'color': '#67aadf'
        }
    ]

    const loadEpisode = async (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault()
        const index: number = parseInt(event.currentTarget.value, 10)
        await setEpisode(episode => {
            return {...(episodes[index] as Episode)}
        })
        setCategory(episode?.category!)
    }

    const displayStations = (stations: MarkerType[]) => {
        return stations.map((station) => {
                return (
                    <div className="map-of-stations">
                        <p className='station-name'>{station.callsign}</p>
                        <p>{station.city}, {station.state}</p>
                    </div>
                )
            }
        )
    }

    const saveEpisode = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const pattern = /(https)([^"]+)/;
        const vidData = await fetch(`https://noembed.com/embed?dataType=json&url=` + episode.video_url).then((response) => {
            return response.json()
        })
        const formData = new FormData()
        formData.append("video_url", vidData.html.match(pattern)[0])
        formData.append("title", episode?.title!)
        formData.append("episode_number", episode?.episode_number!)
        formData.append("desc", episode?.desc!)
        formData.append("category", episode?.category!)
        formData.append("date", format(new Date(), "y-M-d"))
        if(episode?.id){
                await fetch(`${API_URL}/episodes/${episode.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then((response) => {
                    if (response.status >= 400 && response.status < 600) {
                        alert("Bad response from server")
                    } else {
                        window.alert("Episode updated!")
                        window.location.reload()
                        return response.json()
                    }
                })
            } else {
                await fetch(`${API_URL}/episodes`, {
                    method: 'POST',
                    body: formData,
                }).then((response) => {
                    if (response.status >= 400 && response.status < 600) {
                        alert("Bad response from server")
                    } else {
                        window.alert("Episode submitted!")
                        window.location.reload()
                        return response.json()
                    }
                })
            }

    }
    const deleteEpisode = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        const confirm = window.confirm("Are you sure you want to delete?")
        if(confirm){
            await fetch(`${API_URL}/episodes/${episode?.id}`, {
                method: 'DELETE',
            }).then((res) => {
                if (res.status >= 400 && res.status < 600) {
                    alert("Bad response from server")
                } else {
                    window.location.reload()
                    return res.json()
                }
            })
        }
    }

        const displayMarkers = (markers: MarkerType[]) => {
        return markers.map((marker, index) => {
                return (
                    <Marker width={20} anchor={[marker.latitude, marker.longitude]} key={index} />
                )
            }
        )
    }

    return (
        <div className='admin'>
            <header>
                <img src={tlzLogo} alt={"TLZ Logo"}/>
                <p>The Loading Zone Admin Panel</p>
            </header>
            <main>
                <div className='admin-content'>
                    <div className='edit-episodes'>
                        <form onSubmit={saveEpisode}>
                            <div className='add-episode'>
                                <h3>Add Episode</h3>
                                <div className='episode-select'>
                                    <label>Edit Episode</label>
                                    <select onChange={loadEpisode}>
                                        <option value="" hidden={true}>Episode Select</option>
                                        {episodes && episodes.map((episode, index: number) => {
                                            return <option value={index}>{episode.episode_number} - {episode.title}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='vimeo-url label-and-edit'>
                                <label>YouTube Url:</label>
                                <input title={episode?.video_url} value={episode?.video_url ? episode.video_url : ""} onChange={(event) => {
                                    setEpisode((episode) => {
                                        return {...episode, video_url: event.target.value} as Episode
                                    })
                                }} required/>
                            </div>
                            <div className='title label-and-edit'>
                                <label>Title:</label>
                                <input title={episode?.title} value={episode?.title ? episode.title : ""} onChange={(event) => {
                                    setEpisode((episode) => {
                                        return {...episode, title: event.target.value} as Episode
                                    })
                                }} required/>
                            </div>
                            <div className='episode-number'>
                                <div className='label-and-edit label-and-input'>
                                    <label>Episode #:</label>
                                    <input title={episode?.episode_number} value={episode?.episode_number ? episode.episode_number : ""}
                                            onChange={event => {
                                                setEpisodeNumber(event.target.value)
                                                setEpisode((episode) => {
                                                    return {...episode, episode_number: event.target.value} as Episode
                                                })}} pattern="[0-9]*" required/>
                                </div>
                                <div className='episode-status'>
                                    <div className='branding'>
                                        <div className='branding-img'>
                                            {foundEp ? <img src={epFound} alt={"Episode Found"}/> : <img src={epNotFound} alt={"Episode Not Found"}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='description label-and-edit'>
                                <label>Description:</label>
                                <textarea value={episode?.desc ? episode.desc : ""} onChange={event => {
                                    setEpisode((episode) => {
                                        return {...episode, desc: event.target.value} as Episode
                                    })
                                }} required/>
                            </div>
                            <div className='category label-and-edit'>
                                <label>Category:</label>
                                <select onChange={event => {
                                    event.preventDefault()
                                    setEpisode((episode) => {
                                        return {...episode, category: event.target.value} as Episode
                                    })
                                }} required>
                                    <option value="" disabled>Choose Category</option>
                                    <option value="Arts" selected={"Arts" == episode?.category}>Arts</option>
                                    <option value="Engineering" selected={"Engineering" == episode?.category}>Engineering</option>
                                    <option value="Health/Accessibility" selected={"Health/Accessibility" == episode?.category}>Health/Accessibility</option>
                                    <option value="Home/Life Skills" selected={"Home/Life Skills" == episode?.category}>Home/Life Skills</option>
                                    <option value="Math" selected={"Math" == episode?.category}>Math</option>
                                    <option value="Science" selected={"Science" == episode?.category}>Science</option>
                                    <option value="Technology" selected={"Technology" == episode?.category}>Technology</option>
                                    <option value="Other" selected={"Other" == episode?.category}>Other</option>
                                </select>
                            </div>
                            <div className='buttons'>
                                {!episode.id && (
                                    <button type="submit" className='save' disabled={!(foundEp)}>Create Episode</button>
                                )}
                                {episode.id && (
                                    <>
                                        <div className='delete' onClick={e => (episode ? setDeletingEpisode(episode) : null)}>Delete Episode</div>
                                        <button type="submit" className='save'>Save Episode</button>
                                    </>
                                )}
                            </div>
                        </form>
                        {deletingEpisode &&
                        <div className='dialog-box'>
                            <div className='background-color'>
                                <p className='confirm'>Are you sure you want to delete this episode from the National Distribution Site?</p>
                                <div className='info'>
                                    <div className='episode-info'>
                                        <label>Title:</label>
                                        <p>{episode?.title}</p>
                                    </div>
                                    <div className='episode-info'>
                                        <label>Episode #:</label>
                                        <p>{episode?.episode_number}</p>
                                    </div>
                                </div>
                                <div className='buttons dialog-buttons'>
                                    <button className='delete' onClick={e => (setDeletingEpisode(null))}>No</button>
                                    <button className='save' onClick={deleteEpisode}>Yes</button>
                                </div>
                            </div>
                            <div className='background'/>
                        </div>
                        }
                    </div>
                    <hr/>
                    <div className='statistics'>
                        <h3>Statistics</h3>
                        <div className='total'>
                            <div className='downloads'>
                                <label>Total Downloads:</label>
                                <p className='total-downloads'>{stats?.totalDownloads.downloadCount}</p>
                            </div>
                            <div className='station-count'>
                                <label>Total Station Count: </label>
                                <p>{stats?.totalStations.stationCount}</p>
                            </div>
                        </div>
                        <div className='stations'>
                            <div className='station-map'>
                                <Map defaultCenter={[39.828, -98.579]} defaultZoom={3}>
                                    {displayMarkers(stats?.activeStationLocations ? stats?.activeStationLocations : [] as MarkerType[])}
                                </Map>
                            </div>
                            <div className='station-list'>
                                <ul>
                                    <li>
                                        {displayStations(stats ? stats.activeStationLocations : [])}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='most'>
                            <div className='download-eps'>
                                <label>Top 10 Most Downloaded Episodes</label>
                                <div className='most-downloaded'>
                                    <ul>
                                        {stats?.topEpisodes && stats.topEpisodes.map((episode) => {
                                            return (
                                                <div className='downloaded-list'>
                                                    <li className="most-downloaded-list">{episode.episodeCount}</li>
                                                    <li className="most-downloaded-list episode-download">Episode {episode.episode_number} - {episode.title}</li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className='popular'>
                                <label>Downloaded Categories</label>
                                <div className='category-chart'>
                                    {pieChartData &&
                                        <ResponsiveContainer height="90%" width="100%">
                                            <PieChart width={600} height={500}>
                                                <Pie
                                                    legendType={'square'}
                                                    data={pieChartData}
                                                    dataKey={'value'}
                                                    nameKey='category'
                                                    isAnimationActive={true}
                                                >

                                                    {pieChartData && pieChartData.map((entry) => <Cell key={`cell-${entry.value}`} fill={entry.color}/>)}
                                                </Pie>
                                                <Tooltip/>
                                                <Legend/>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Admin;